import React, { FC }       from "react"
import { useSiteMetadata } from "../../../hooks/useSiteMetaData"
import { Link }            from "gatsby"


const Copyright: FC = () => {
  
  let { entityName } = useSiteMetadata()
  
  return (
    <>© { `${ new Date().getFullYear() }, ${ entityName }` } | <Link to="/privacy-policy">Privacy Policy</Link></>
  )
}

export default Copyright
