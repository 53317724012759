import { useStaticQuery, graphql } from "gatsby"

export const useSiteMetadata = () => {
  const { site } = useStaticQuery<GatsbyTypes.SiteMetaDataQuery>(
    graphql`
        query SiteMetaData {
            site {
                siteMetadata {
                    title
                    description
                    entityName
                    entityLocation
                    siteAuthor
                    siteUrl
                    siteTags
                }
            }
        }
    `
  )
  return site.siteMetadata
}
