import React, { FC } from "react"
import { Link }      from "gatsby"
import { MailIcon }  from "@heroicons/react/solid"

//@ts-ignore
import logo           from "../../../images/logo.svg"
import classNames     from "classnames"
import { MailToLink } from "./MailToLink"

interface Props {
  siteTitle: string
}

const defaultProps: Props = {
  siteTitle: "TITLE"
}

const PageHeader: FC<Props> = ( { siteTitle } ) => (
  
  <div className={ `text-neutral-content p-5 md:p-10` }>
    <div className={ classNames( `max-w-screen-sm m-auto relative` ) }>
      
      {/* LOGO */}
      <h1 className={ `flex-none md:flex md:justify-center` }>
        <Link to="/" className={ "md:block" }>
          <img src={ logo } alt={ siteTitle } width={ 200 } />
        </Link>
      </h1>
      
      {/* Small Screen Mail To */}
      <div className={ "absolute top-[10px] right-0 md:hidden text-slate-500" }>
        <MailToLink>
          <MailIcon height={ 30 } />
        </MailToLink>
      </div>
    
    </div>
  </div>
)

PageHeader.defaultProps = defaultProps

export default PageHeader
