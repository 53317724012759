import React, { FC } from "react"

interface Props {
  email?: string,
  subject?: string,
  body?: string,
}

const defaultProps: Props = {
  email:   `gjaspan@byrdalley.com`,
  subject: `Introductions%20%26%20Project%20Inquiry`,
  body:    `Hi%20Team%20%40%20Byrd%20Alley!%0A%0AI%20have%20a%20%5Bcompany%5D%20that%20works%20in%20the%20%5Bindustry-type%5D%20industry%2C%20where%20we%20%5Bwhat%20you%20do%5D.%20I'm%20interested%20in%20talking%20with%20you%20about%20%5Bsome%20role%20or%20work%20skill%5D%20and%20wanted%20to%20reach%20out%20to%20set%20up%20a%20time%20to%20meet%20and%20discuss%20the%20prospect%20%5Byour%20goals%5D.%0A%0AMy%20schedule%20is%20free%20starting%20%5Bwhen%20you%20are%20available%5D%2C%20and%20you%20can%20reach%20me%20or%20my%20team%20at%20%5Ba%20phone%20number%5D.%0A%0AThanks!`
}
const MailToLink: FC<Props> = ( props ) => {
  
  return (
    
    <a href={ `mailto:${ props.email }?subject=${ props.subject }&body=${ props.body }` }>
      { props.children }
    </a>
  )
}

MailToLink.defaultProps = defaultProps

export { MailToLink }
