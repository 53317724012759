import React, { FC } from "react"

import { useSiteMetadata } from "../../../hooks/useSiteMetaData"
import PageHeader          from "./PageHeader"
import PageFooter          from "./PageFooter"

interface Props {}

const defaultProps: Props = {}

const PageLayout: FC<Props> = ( props ) => {
  
  let { title } = useSiteMetadata()
  
  return (
    <div className={ `fixed inset-0 overflow-x-hidden overflow-y-scroll` }>
      
      {/* Foreground */ }
      <div className={ "relative z-10 animate-fade-in" }>
        
        {/* Header */ }
        <nav className={ `sticky top-0 relative z-10 ` }>
          
          <PageHeader siteTitle={ title ?? `Title` } />
        
        </nav>
        
        {/* Main Content */ }
        <main className={ `relative z-0` }>
          
          { props.children }
        
        </main>
        
        {/* Footer */ }
        <PageFooter />
      
      </div>
    </div>
  )
}

PageLayout.defaultProps = defaultProps

export { PageLayout }
