import classNames    from "classnames"
import React, { FC } from "react"

interface Props {
  as?: any
  maxContentSize?: "sm" | "md" | "lg" | "xl" | "2xl",
  
  [ additionalProps: string ]: any
}

const defaultProps: Props = {
  as:             "div",
  maxContentSize: "sm"
}

const StackBlock: FC<Props> = ( props ) => {
  
  let { as, maxContentSize, ...componentProps } = props,
      ContainingComponent                       = as
  
  return (
    
    <ContainingComponent { ...componentProps }>
      
      <div className={ classNames( `max-w-screen-sm m-auto` ) }>
        
        { props.children }
      
      </div>
    
    </ContainingComponent>
  )
}

StackBlock.defaultProps = defaultProps

/* VARIANTS */
const ClearStackBlock: FC<Props>          = ( props ) => <StackBlock { ...props } className={ classNames( `text-neutral-content p-5 sm:p-5 md:p-20`, props.className ) }>{ props.children }</StackBlock>
const DarkSlateGlassStackBlock: FC<Props> = ( props ) => <StackBlock { ...props } className={ classNames( `bg-slateGlass text-neutral-content p-5 sm:p-5 md:p-20`, props.className ) }>{ props.children }</StackBlock>
const DarkSlateStackBlock: FC<Props>      = ( props ) => <StackBlock { ...props } className={ classNames( `bg-slate-900 text-neutral-content p-5 sm:p-5 md:p-20`, props.className ) }>{ props.children }</StackBlock>
const SlateStackBlock: FC<Props>          = ( props ) => <StackBlock { ...props } className={ classNames( `bg-slate-700 text-neutral-content p-5 sm:p-5 md:p-20`, props.className ) }>{ props.children }</StackBlock>
const GreenStackBlock: FC<Props>          = ( props ) => <StackBlock { ...props } className={ classNames( `bg-green-700 text-neutral-content p-5 sm:p-5 md:p-20`, props.className ) }>{ props.children }</StackBlock>

export {
  StackBlock,
  DarkSlateStackBlock,
  DarkSlateGlassStackBlock,
  SlateStackBlock,
  GreenStackBlock,
  ClearStackBlock
}
