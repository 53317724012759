import React, { FC }  from "react"
import { Link }       from "gatsby"
import { StackBlock } from "./StackBlock"
import Copyright      from "./Copyright"

interface Props {}

const defaultProps: Props = {}

const PageFooter: FC<Props> = ( props ) => (
  
  <StackBlock as={ "footer" }>
    
    <p className={ `text-center text-sm text-slate-700 font-medium py-1 md:py-5` }>
      
      <Copyright />
      
    </p>
  
  </StackBlock>
)

PageFooter.defaultProps = defaultProps

export default PageFooter
