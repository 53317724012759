import React, { FC }       from "react"
import { Helmet }          from "react-helmet"
import { useSiteMetadata } from "../../../hooks/useSiteMetaData"

//@ts-ignore
import metaSummaryCard from "../../../images/meta-summary-card.png"

interface Props {
  pageTitle?: string,
  description?: string,
  lang?: string,
  meta?: object[],
}

const defaultProps: Props = {
  pageTitle:   null,
  description: null,
  lang:        `en`,
  meta:        []
}

const PageMeta: FC<Props> = ( props ) => {
  
  let { title, description, entityName, entityLocation, siteTags, siteUrl } = useSiteMetadata()
  
  const metaDescription = props.description ?? description,
        pageTitle       = props.pageTitle ?? null,
        defaultLang     = props.lang ?? defaultProps.lang
  
  return (
    
    <>
      <Helmet
        htmlAttributes={ {
          lang:         defaultLang,      // language
          class:        "bg-slate-900",   // background color of page
          "data-theme": "byrdalley"       // used for / with DaisyUI
        } }
        bodyAttributes={ {
          class: "bg-slate-900"           // background color of body, etc.
        } }
        title={ entityName }
        titleTemplate={ pageTitle ? `${ title } | ${ pageTitle } | %s` : title }
        meta={ [
          {
            name:    `description`,
            content: metaDescription
          },
          {
            name:    `keywords`,
            content: siteTags
          },
          {
            property: `og:title`,
            content:  title
          },
          {
            property: `og:description`,
            content:  metaDescription
          },
          {
            property: `og:type`,
            content:  `website`
          },
          {
            property: `og:url`,
            content:  siteUrl
          },
          {
            property: `og:image`,
            content:  metaSummaryCard
          },
          {
            property: `twitter:card`,
            content:  `summary_large_image`
          },
          {
            property: `twitter:url`,
            content:  siteUrl
          },
          {
            property: `twitter:title`,
            content:  title
          },
          {
            property: `twitter:description`,
            content:  metaDescription
          },
          {
            property: `twitter:image`,
            content:  metaSummaryCard
          },
          
          ...props.meta
        
        ] } />
    
    </>
  
  )
}

PageMeta.defaultProps = defaultProps

export { PageMeta }
